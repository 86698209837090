
  import { Component } from 'vue-property-decorator'
  import Cell from './cell'
  import { Icon } from '@/entities/settings'
  import { mapActions } from 'vuex'

@Component({
  methods: {
    ...mapActions('resources/datatables', ['checkMoreThanOneFinancier']),
  },
})
  export default class CloseEvaluationCell extends Cell {
  checkMoreThanOneFinancier!: ({ id, financierId }) => Promise<Record<string, any>>

  hasFinancing = false
  declare options: {
    set: Record<string, Icon>
  }

  async mounted () {
    const resp = await this.checkMoreThanOneFinancier({
      id: this.item.financing.saleOrder?.id,
      financierId: this.item.financing.id,
    })

    if (this.item?.closingReason?.name !== 'signed') {
      this.hasFinancing = resp?.check
    }
  }

  trigger (value) {
    const { item, options } = this

    const trigger = options?.action?.trigger || item?.trigger

    if (trigger) {
      return trigger(value.buttonClose)
    }
  }

  get disabledButton () {
    return this.item.buttonClose?.disabled
  }

  get icon () {
    return this.options?.action?.icon
  }

  get color () {
    return this.options?.action?.color
  }
  }
